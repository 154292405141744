.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Flip Card Styles */
.flip-card {
  position: relative;
  width: 100%;
  height: 100%;
  perspective: 1500px; /* Increase perspective for a better 3D effect */
}

.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;
  transition: transform 0.8s ease-in-out;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  padding: 16px;
}

/* Front Side Styles */
.flip-card-front {
  background-color: #f0c40d;
  color: black;
}

/* Back Side Styles */
.flip-card-back {
  background-color: #fff;
  transform: rotateY(180deg);
  color: #333;
}

/* Icon Styles */
.flip-card-icon {
  width: 48px;
  height: 48px;
  margin-bottom: 12px;
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .flip-card {
    height: 250px; /* Adjust height for smaller screens */
  }

  .flip-card-icon {
    width: 36px;
    height: 36px;
  }

  .flip-card-front,
  .flip-card-back {
    padding: 12px;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .flip-card {
    height: 300px;
  }

  .flip-card-icon {
    width: 40px;
    height: 40px;
  }
}

@media (min-width: 1024px) {
  .flip-card {
    height: 350px;
  }

  .flip-card-icon {
    width: 48px;
    height: 48px;
  }
}
